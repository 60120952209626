@use '~@etchteam/mobius/src/styles/variables/breakpoint';

.nav {
  --nav-hover-background: rgba(255, 255, 255, 0.1);

  &__open {
    background: var(--color-background);
    color: var(--color-body);
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: auto;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity var(--transition-duration) var(--transition-timing);
    transition-delay: var(--transition-duration);
    width: 100%;
  }

  &__content {
    padding: var(--spacing-fluid) 0;
  }

  &__inner {
    opacity: 0;
    transform: translateY(1em);
    transition:
      transform var(--transition-duration) var(--transition-timing),
      opacity var(--transition-duration) var(--transition-timing);
    transition-delay: 0s;
  }

  &__item {
    align-items: center;
    color: var(--color-white);
    display: inline-flex;
    height: calc(1.75rem + var(--spacing-md) * 2);
    justify-content: center;
    padding: 0 var(--spacing-md);
    transition: background-color var(--transition-duration)
      var(--transition-timing);
    vertical-align: middle;

    &:hover {
      background-color: var(--nav-hover-background);
    }

    &--toggle {
      // icon
      > span {
        font-size: var(--font-size-xl);
        transform: translateY(0.075em);
      }
    }

    &--logo {
      display: none;

      @media screen and (min-width: breakpoint.$md) {
        display: inline-flex;
      }
    }
  }

  &__user {
    align-items: center;
    border-radius: 50%;
    color: var(--color-forest);
    display: flex;
    height: var(--spacing-thumb);
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: var(--spacing-thumb);

    &-gravatar,
    &-text {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &-gravatar {
      height: 100%;
      width: 100%;
    }
  }

  &__dropdown {
    min-width: 12.5em;
    padding: var(--spacing-sm) 0;

    &-link {
      display: block;
      padding: var(--spacing-sm) var(--spacing-md);
      transition: background-color var(--transition-duration)
        var(--transition-timing);
      white-space: nowrap;

      > span {
        color: var(--color-body);
      }

      &:hover {
        background: var(--skin-background-muted);
      }
    }
  }

  &--open {
    .nav__open {
      opacity: 1;
      pointer-events: auto;
      transition-delay: 0s;
    }

    .nav__inner {
      opacity: 1;
      transform: none;
      transition-delay: var(--transition-duration);
    }
  }

  &__image {
    margin: 0 auto;
  }
}
