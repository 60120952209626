@use '~@etchteam/mobius/src/styles/variables/breakpoint';

.logomark {
  display: flex;

  &:hover {
    text-decoration: none;
  }

  &__img {
    height: 36px;
    margin-right: var(--spacing-sm);
    width: 182px;
  }

  &--white {
    color: var(--color-white);
    transform: translate(0, 0.2em);

    .logomark__img {
      height: 36px;
      margin-right: var(--spacing-xs);
      width: 182px;
    }
  }
}
