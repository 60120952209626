.global-alerts {
  bottom: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0;
  z-index: 10;

  &__alert {
    margin: var(--spacing-md) var(--spacing-md);
    max-width: 20em;
    width: 100vw;
  }
}
