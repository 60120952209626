.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__main {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column; // Is this a terrible idea? Making the error 100% height
  }
}
