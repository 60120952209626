@use '~@etchteam/mobius/src/styles/variables/breakpoint';

.title-bar-logged-out {
  border-top: 0.25em solid var(--color-forest);
  padding: var(--spacing-md) 0;

  &--white {
    background: var(--color-white);
  }

  @media (min-width: breakpoint.$md) {
    padding: var(--spacing-lg) 0;
  }
}
