@use '~@etchteam/mobius/src/styles/variables/breakpoint';

.home-illustration {
  margin: var(--spacing-xl) 0 0;
  text-align: center;

  img {
    display: inline-block;
    max-width: breakpoint.$sm;
    width: 100%;
  }

  @media screen and (min-width: breakpoint.$xl) {
    margin: -2em 0 0;
    text-align: right;

    img {
      max-width: breakpoint.$md;
    }
  }
}
