.error-page {
  align-items: center;
  background: var(--color-sand-25);
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;

  &__content {
    padding: var(--spacing-md) var(--spacing-md);
  }

  &__code {
    color: var(--color-forest-75);
    font-size: 6rem;
    font-weight: var(--font-weight-bold);
  }

  &__img {
    margin: auto;
  }
}
