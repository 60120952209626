.title-bar {
  align-items: center;
  background: var(--color-forest);
  color: var(--color-white);
  display: flex;
  min-height: var(--header-height);
  position: relative;
  user-select: none;
  z-index: 4;

  &__inner {
    width: 100%;
  }

  &--muted {
    background: var(--color-forest-75);
  }
}
