.link-arrow {
  align-items: baseline;
  color: var(--color-forest-75);
  display: flex;
  padding: var(--spacing-md) var(--spacing-md);
  transition: background var(--transition-duration) var(--transition-timing);

  &:hover,
  &:focus {
    background: var(--color-sand-25);
  }

  &__content {
    flex: 1 1 auto;
  }

  &--disabled {
    color: inherit;
    pointer-events: none;
  }

  &--border {
    + .link-arrow--border {
      border-top: 1px solid var(--color-earth-50);
    }
  }
}
